import jQuery from "jquery";

// Setup image slider on home page.
export class FrontPageSlideShow {
    private nMaxImageWidth: number = 0;
    private nIntervalId: NodeJS.Timeout | null;
    private readonly msTimerDuration: number = 4000;
    private currentImage = jQuery('.SliderImg:last') as JQuery<HTMLImageElement>; // Set to last so next shown slide will be the first one

    public constructor() {
        window.onresize = () => this.SetDivHeight(); // Recalc div size when window size changes.

        jQuery('.SliderImg').hover(
            () => {
                if (this.nIntervalId !== null) {
                    clearInterval(this.nIntervalId); // Pause image slider when user hovers over image.
                    jQuery('#SliderPausedMsg').show();
                }
            },
            () => {
                jQuery('#SliderPausedMsg').hide();
                this.nIntervalId = setInterval(() => this.ShowNextSlide(), this.msTimerDuration) // Restart timer
            }
        );

        const Images = jQuery('.SliderImg') as JQuery<HTMLImageElement>; //[];
        this.nMaxImageWidth = Math.max(...Images.map((_, img) => img.width));

        this.SetDivHeight();
        this.ShowNextSlide();
        this.nIntervalId = setInterval(() => this.ShowNextSlide(), this.msTimerDuration);
    }

    private SetDivHeight() { // Adjust size of Div initially and when window size changes.
        const maxDivWidth: number = 900;
        const maxDivHeight: number = 320;
        const screenWidth: number = document.getElementById('MySliderDiv')!.clientWidth;
        const nEffectiveWidthLimit: number = Math.min(screenWidth, maxDivWidth);
        if (this.nMaxImageWidth > nEffectiveWidthLimit) // Max image is wider than allowed
            document.getElementById('MySliderDiv')!.setAttribute('height', (maxDivHeight * (nEffectiveWidthLimit / maxDivWidth)).toString()); // Set height of Div.
    }

    private ShowNextSlide() {
        this.currentImage.hide();
        if (this.currentImage.next('.SliderImg').length)
            this.currentImage = this.currentImage.next('.SliderImg');
        else
            this.currentImage = this.currentImage.parent().children('.SliderImg:first') as JQuery<HTMLImageElement>;
        this.currentImage.fadeIn();
    }
}
