export class MyRecaptchaHandler {
    private bRecaptchaV3Available = false;
    private defRecaptchaSetupDone = jQuery.Deferred(); // Use to indicate background processing is done
    private defUserAccepted = jQuery.Deferred(); // Use to enable submit button when recatpcha test is passed
    private UserPassedTest = false;

    constructor(
        protected readonly V3FormName: string,
        protected readonly V3SubmittedAction: string,
        protected readonly siteKeyV2: string,
        protected readonly siteKeyV3: string
    ) {
        grecaptcha.ready(async () => {
            // Check if reCaptcha V3 is available, otherwise setup V2
            let OneTimeV3Token = await grecaptcha.execute(this.siteKeyV3, { action: this.V3FormName });
            let URL = '/BTCAPI/Recaptcha/reCaptchaPassiveV3Check/' + this.V3FormName + '/' + OneTimeV3Token;
            let jsonV3AvailabilityResp = await fetch(URL);
            if (jsonV3AvailabilityResp.status === 503)  // Service unavailable?
                jQuery('#ErrorMessage').html("BTC API is offline or inaccessible");
            this.bRecaptchaV3Available = jsonV3AvailabilityResp.status === 200 && await jsonV3AvailabilityResp.json() as boolean;
            if (this.bRecaptchaV3Available) {
                // V3 tokens expire in two minutes, so don't bother saving it (https://developers.google.com/recaptcha/docs/v3)
                this.UserPassedTest = true;
                this.StoreV3Token("V3IsAvailable"); // Store placeholder in page instead to indicate V3 is available
                await this.defUserAccepted.resolve(); // Notify form user has been accepted as not a bot (by V3)
            }
            else { // No V3 available so setup recaptcha V2
                let elem = document.getElementsByClassName('g-recaptcha')[0] as HTMLElement;
                grecaptcha.render(elem, { 'sitekey': this.siteKeyV2 });
                jQuery('.g-recaptcha').prop("style", "display:block"); // Make V2 recaptcha visible
            }
            await this.defRecaptchaSetupDone.resolve(); // Tell form long-ish recaptcha setup is done so spinner can be hidden

        });
    }

    // Only expose promise object, not deferred object: https://medium.com/@codeliter/jquery-deferred-vs-promise-b3e13c00f921
    public promRecaptchaSetupDone = async () => await this.defRecaptchaSetupDone.promise();
    public promUserAccepted = async () => await this.defUserAccepted.promise();

    public GetUserPassedTest = () => this.UserPassedTest;

    // recaptcha object will call this function if/when user has passed V2 test
    public onV2VerifiedNotARobot = async () => {
        this.UserPassedTest = true;
        await this.defUserAccepted.resolve();
    }

    // When user submits the form using recaptcha V3, get a new V3 token
    public async GetNewOneTimeV3TokenIfNeeded(): Promise<void> {
        if (!this.bRecaptchaV3Available)
            return;

        let Placeholder = (document.getElementById("reCaptchaV3Token") as HTMLInputElement).value;
        if (Placeholder !== "") { // recaptcha V3 is available?
            // Get new recaptcha V3 token and record into form so it can be passed to server for validation.
            let OneTimeV3Token = await grecaptcha.execute(this.siteKeyV3, { action: this.V3SubmittedAction });
            this.StoreV3Token(OneTimeV3Token);
        }
    }

    private StoreV3Token = (OneTimeV3Token: string) =>
        (document.getElementById("reCaptchaV3Token") as HTMLInputElement).value = OneTimeV3Token;
}
