import { EditRecordBase } from "../Lib/EditRecordBase";

export class EditBreweryRun extends EditRecordBase {
    constructor(protected readonly TargetAPIUrl: string, protected readonly HttpVerb: string) {
        super(TargetAPIUrl, HttpVerb);
    }

    // Return URL to reload screen if new record was created
    protected override BuildGotoURL = (NewRecordId: any): string => {
        if (NewRecordId === undefined) // Server didn't return a new record Id becase the user is updating an existing record
            return "";
        else
            return "/admin/EditBreweryRun?RecordId=" + NewRecordId;
    }

    EnableDisableControls() {
        super.EnableDisableControls();

        const PreviewAnchor: HTMLAnchorElement = document.getElementById("SendBreweryRunEmailPreview") as HTMLAnchorElement;
        if (PreviewAnchor != null) {
            const FirstSaveMsg: HTMLSpanElement = document.getElementById("SaveToPreviewMessage") as HTMLSpanElement;
            if (this.IsDirty) {
                PreviewAnchor.style.display = "none";
                FirstSaveMsg.style.display = "inline";
            }
            else {
                PreviewAnchor.style.display = "inline";
                FirstSaveMsg.style.display = "none";
            }
        }
    }
}
