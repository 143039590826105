import jQuery from "jquery";

export class BTCJoinRenew {
    constructor(protected bUserSignedIn: boolean, protected bTooSoonToRenew: boolean, protected QualifiedForRenewal: boolean) {
        document.getElementById('RegistrationTypeNew')?.addEventListener('change', () => this.ShowHideJoinRenew(bTooSoonToRenew, bUserSignedIn));
        document.getElementById('RegistrationTypeExisting')?.addEventListener('change', () => this.ShowHideJoinRenew(bTooSoonToRenew, bUserSignedIn));

        this.ShowHideJoinRenew(bTooSoonToRenew, bUserSignedIn); // Show renew section in case known user is already logged in

        const DonationAmountOther: HTMLInputElement = document.getElementById('DonationOtherAmount') as HTMLInputElement;
        jQuery('input[name="DonationPreSetAmt"]').each((_, rb) =>
            rb.addEventListener('change', (event: Event) =>
                DonationAmountOther.disabled = (event.currentTarget as HTMLInputElement).value !== 'Other'));

        DonationAmountOther.disabled = true;
    }

    private ShowHideJoinRenew(bTooSoonToRenew: boolean, bUserIsSignedIn: boolean) {
        // It's possible that neither radio button is checked when the page is first loaded and the user isn't logged in
        const RenewingMemberChecked: boolean = jQuery("#RegistrationTypeExisting").prop('checked');
        if (RenewingMemberChecked)
            jQuery('#divRenewNextStepsInfo').show('slow');
        else
            jQuery('#divRenewNextStepsInfo').hide('slow');

        const NewMemberChecked: boolean = jQuery("#RegistrationTypeNew").prop('checked');

        if (!this.bUserSignedIn && !NewMemberChecked)
            jQuery('#divPriceSchedule').hide('slow');
        else
            jQuery('#divPriceSchedule').show('slow');

        // Show price schedule and reg form
        if (NewMemberChecked || (bUserIsSignedIn && !bTooSoonToRenew)) {
            jQuery('#Form_Fields').show('slow');
        }
        else {
            jQuery('#Form_Fields').hide('slow');
        }
    }
}
