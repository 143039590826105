import jQuery from "jquery";
import { EditRecordBase } from "../EntryPoints";
import { HelperFunctions } from "../EntryPoints";

export class EditRaceResults extends EditRecordBase {
    constructor(protected readonly TargetAPIUrl: string, protected readonly KnownEventId: number,
        protected readonly KnownEventRaceId: number, protected readonly EditingExistingResult: boolean) {
        super(TargetAPIUrl, 'PUT', '/btc-news/my-race-results');

        // Setup event handlers
        document.getElementById("EventsList")?.addEventListener("change", () => this.FilterRacesBySelectedEvent());
        document.getElementById("rbUseExistingEvent")?.addEventListener("change", () => this.ShowHideEventEditingSections());
        document.getElementById("rbEnterNewEvent")?.addEventListener("change", () => this.ShowHideEventEditingSections());
        document.getElementById("rbUseExistingRace")?.addEventListener("change", () => this.ShowHideRaceEditingSections());
        document.getElementById("rbNewRace")?.addEventListener("change", () => this.ShowHideRaceEditingSections());
        document.getElementById("UseUncommonDistance")?.addEventListener("change", () => this.ShowHideAltRaceDistances());
        document.getElementById("ShowStravaLinkInstructions")?.addEventListener("change", () => this.ShowHideStravaRaceLinkInstructions());

        if (this.EditingExistingResult) { // Hide Event and Race info sections
            jQuery("#fsEvent").hide();
            jQuery("#fsRace").hide();
            jQuery("#legendStep3").html("Race Result");
        }
        else {
            jQuery("#legendStep3").html("Step 3: Race Result");
            jQuery("#UpdateRaceSummaryDesc").hide();

            jQuery.when(this.AjaxLoadEvents(), this.AjaxLoadEventRaces())
                .then(() => {
                    (document.getElementById("EventsList") as HTMLSelectElement).value = this.KnownEventId.toString();
                    this.FilterRacesBySelectedEvent(); // Call manually since setting selected EventId value manually doesn't fire changed event
                    (document.getElementById("EventRaces") as HTMLSelectElement).value = KnownEventRaceId.toString();

                    this.ShowHideEventEditingSections();
                    this.ShowHideRaceEditingSections();
                    this.ShowHideAltRaceDistances();
                    this.ShowHideStravaRaceLinkInstructions();
                });

        }

        this.ShowHideEventEditingSections();
    }

    private AjaxLoadEvents() {
        const URL: string = '/BTCAPI/EditRaceResults/Events';
        return jQuery
            .get(URL,
                (JsonData: [{ date: string, eventId: string, eventName: string }]) => {
                    if (JsonData !== null) {
                        const select = jQuery('#EventsList') as JQuery<HTMLSelectElement>;
                        select.empty(); // Clear all options in select element
                        JsonData.forEach((Event) => select.append(
                            jQuery('<option value="' + Event.eventId + '" >' + Event.date + ': ' + Event.eventName + '</option>')));

                    }
                }, "json")
            .fail(async (jqXHR, textStatus, errorThrown) =>
                await HelperFunctions.DisplayAndProcessAjaxFailure(URL, "GET", jqXHR, textStatus, errorThrown, null, null)
            )
            ;
    }

    // Get all races for all events and put them into 'eventsraceinfo' data store to be retreived as needed.
    private AjaxLoadEventRaces() {
        const URL: string = '/BTCAPI/EditRaceResults/EventRaces';
        return jQuery
            .get(URL,
                (JsonData: [{ eventRaceId: string, eventId: string, description: string, distance: string }]) =>
                    jQuery("#EventRaces").data('eventsraceinfo', JsonData),
                "json")
            .fail(async (jqXHR, textStatus, errorThrown) =>
                await HelperFunctions.DisplayAndProcessAjaxFailure(URL, "GET", jqXHR, textStatus, errorThrown, null, null)
            )
            ;
    }

    private ShowHideEventEditingSections() {
        if (!this.EditingExistingResult) { // Editing new race result?
            const UseExisingEvent: boolean = jQuery('#rbUseExistingEvent').prop('checked');

            if (UseExisingEvent) {
                jQuery("#EventsList").show('fast');
                jQuery("#divEnterNewEvent").hide('fast');
                jQuery("#RacesOptions").show('fast');
            }
            else {
                jQuery("#EventsList").hide('fast');
                jQuery("#divEnterNewEvent").show('fast').css("display", "flex");

                // For new events, user must add new race info
                jQuery("#rbNewRace").prop('checked', true);
                jQuery("#RacesOptions").hide('fast');
            }
        }

        this.ShowHideAltRaceDistances();
        this.ShowHideRaceEditingSections();
    }

    private ShowHideRaceEditingSections() {
        if (jQuery('#rbUseExistingRace').prop('checked')) {
            jQuery("#EventRaces").show('fast');
            jQuery("#divEnterNewRace").hide('fast');
        }
        else {
            jQuery("#EventRaces").hide('fast');
            jQuery("#divEnterNewRace").show('fast');
        }

        let KnownEventNotSelected: boolean = jQuery('#rbUseExistingEvent').prop('checked') && // Existing Event?
            (Number(jQuery("#EventsList").find(":selected").val()) || 0) == 0; // But no event selected?

        if (KnownEventNotSelected) { // Event section not valid, so disable race section
            // Then cannot use step 2
            jQuery("#rbUseExistingRace").prop('disabled', true);
            jQuery("#rbNewRace").prop('disabled', true);
            jQuery("#EventRaces").prop('disabled', true);
            jQuery("#CommonRaceDistance").prop('disabled', true);
            jQuery("#UseUncommonDistance").prop('disabled', true);
            jQuery("#RaceDescription").prop('disabled', true);
        }
        else {
            jQuery("#rbUseExistingRace").prop('disabled', false);
            jQuery("#rbNewRace").prop('disabled', false);
            jQuery("#EventRaces").prop('disabled', false);
            jQuery("#CommonRaceDistance").prop('disabled', false);
            jQuery("#UseUncommonDistance").prop('disabled', false);
            jQuery("#RaceDescription").prop('disabled', false);
        }
    }

    private ShowHideAltRaceDistances() {
        if (jQuery('#UseUncommonDistance').prop('checked')) { // Using alternative distance?
            jQuery("#divCommonDistances").hide('fast');
            jQuery("#divUncommonDistances").show('fast');
        }
        else {
            jQuery("#divUncommonDistances").hide('fast');
            jQuery("#divCommonDistances").show('fast');
        }
    }

    private ShowHideStravaRaceLinkInstructions() {
        if (jQuery('#ShowStravaLinkInstructions').prop('checked'))
            jQuery("#StravaLinkInstructions").show('fast');
        else
            jQuery("#StravaLinkInstructions").hide('fast');
    }

    private FilterRacesBySelectedEvent() {
        const SelectedEventId = Number(jQuery("#EventsList").find(":selected").val()) || 0;

        const jqSelEventRaces = jQuery("#EventRaces") as JQuery<HTMLSelectElement>;
        jqSelEventRaces.empty(); // Clear options from select element.
        const EventRacesData: [{ description: string, distance: string, eventId: string, eventRaceId: string }] =
            jqSelEventRaces.data('eventsraceinfo'); // Retreive Json data from jQuery's data store.

        const EventRaces = EventRacesData.filter(RaceEventInfo => parseInt(RaceEventInfo.eventId) === SelectedEventId);
        for (const RaceEventInfo of EventRaces) {
            const Description: string = RaceEventInfo.distance + (RaceEventInfo.description !== '' ? ', ' + RaceEventInfo.description : '');
            const NewOptionElement = jQuery('<option>').text(Description).val(RaceEventInfo.eventRaceId)[0];
            jqSelEventRaces.append(NewOptionElement);
        }

        // If there's only one option and it's not already selected, then auto-select it.
        const selEventRaces = jqSelEventRaces[0]; // Get the actual raw select element from jQuery
        if (selEventRaces.options.length === 1 && selEventRaces.selectedIndex === -1)
            selEventRaces.selectedIndex = 0;

        this.ShowHideRaceEditingSections();
    }
}
