import jQuery from "jquery";
import { PageBusyIndicator } from "./PageBusyIndicator";
import { ReportErrorToServer } from "./ReportErrorToServer";

export class SendEmailBase {
    protected pageBusyIndicator = new PageBusyIndicator();

    constructor(public ServerPageUrl: string) {
        (document.getElementById('Submit') as HTMLButtonElement).addEventListener('click', async () => await this.DoSubmit());

        const elFiles = document.getElementById('FileUpload') as HTMLInputElement | null;
        elFiles?.addEventListener('change', () => this.ValidateFileSizes(elFiles));
        this.pageBusyIndicator.Decrement();
    }

    private ValidateFileSizes(elFiles: HTMLInputElement) { // From: https://stackoverflow.com/a/8758614
        let TotalFilesSize = 0;
        if (elFiles.files !== null)
            Array.from(elFiles.files).forEach((file) => TotalFilesSize += file.size);

        let Valid = true;
        document.getElementById('ErrorMessage')!.innerHTML = '';

        const MaxAllowedAttachmentsSizeBytes = parseInt((document.getElementById('MaxFilesSizeBytes') as HTMLInputElement).value);
        if (TotalFilesSize > MaxAllowedAttachmentsSizeBytes) {
            document.getElementById('ErrorMessage')!.innerHTML = "Error: The size of the attachments is " +
                Math.round(TotalFilesSize / 1024 / 1024) +
                "MB. The limit is " + Math.round(MaxAllowedAttachmentsSizeBytes / 1024 / 1024) + "MB";
            Valid = false;
        }

        (document.getElementById("Submit") as HTMLButtonElement).disabled = !Valid;
    }

    protected async DoSubmit() {
        await this.pageBusyIndicator.Increment();
        jQuery('#UploadProgress').show();
        jQuery('#SuccessMessage').hide();
        jQuery('#ErrorMessage').text('');

        const MyForm = new FormData(document.getElementById("RunEmailSetup") as HTMLFormElement);
        jQuery('form').prop("disabled", true).fadeTo(0, 0.5); // Disable while processing Ajax

        const URL: string = this.ServerPageUrl;
        const HttpVerb = "POST";
        jQuery
            .ajax({ // Support for file uploads. From: https://stackoverflow.com/a/8758614
                method: HttpVerb,
                url: URL,
                data: MyForm,
                contentType: false,
                processData: false,
                xhr: () => { // Async file uploads: https://stackoverflow.com/a/49730801
                    let xhr = new window.XMLHttpRequest();
                    xhr.upload.addEventListener("progress", (evt: ProgressEvent) => {
                        if (evt.lengthComputable && evt.total !== 0)
                            jQuery('progress').attr({ value: evt.loaded, max: evt.total });
                    }, false);
                    return xhr;
                },
                dataType: "json"
            })
            .done(async (JsonData: string) => {
                jQuery('#SuccessMessage').html(JsonData);
                jQuery('#SuccessMessage').show();
            })
            .fail(async (jqXHR, textStatus, errorThrown) => {
                await ReportErrorToServer.DisplayAndProcessAjaxFailure(URL, HttpVerb, jqXHR, textStatus, errorThrown, MyForm, null);
            })
            .always(async () => {
                await this.pageBusyIndicator.Decrement();
                jQuery('form').prop("disabled", false).fadeTo(0, 1); // Re-enable after processing Ajax
                jQuery('#UploadProgress').hide();
            })
            ;
    }
}
