import { initializeApp } from 'firebase/app';
import { Auth, getAuth } from 'firebase/auth';
import { PageBusyIndicator } from "../Lib/PageBusyIndicator";

export class SignInSignOutBase {
    protected readonly auth: Auth;
    protected pageBusyIndicator = new PageBusyIndicator();

    constructor() {
        // Google API key docs: https://cloud.google.com/docs/authentication/api-keys#securing_an_api_key
        let MyFirebaseConfig = { // From: https://console.firebase.google.com/u/1/project/btc-web/settings/general
            apiKey: "AIzaSyBLp9uv6COqH1Yx_bnmi_yj5TzvdG90914",
            authDomain: "btc-web.firebaseapp.com",
            databaseURL: "https://btc-web.firebaseio.com",
            projectId: "btc-web",
            storageBucket: "btc-web.appspot.com",
            messagingSenderId: "659862730493",
            appId: "1:659862730493:web:3699831cc6f30a12ca3094",
            measurementId: "G-87M9C81G1T",
        };

        const app = initializeApp(MyFirebaseConfig);
        this.auth = getAuth(app);
    }
}
