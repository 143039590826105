import { UserEditableHtmlContent } from "../Lib/UserEditableHtmlContent";
import { SendEmailBase } from "../Lib/SendEmailBase";
import { HelperFunctions } from "../EntryPoints";

interface jsonGroupMembers extends Array< {
    memberId: number;
    memberName: string;
    active: boolean;
    email: string;
}> { }

export class SendGroupEmail extends SendEmailBase {
    constructor() {
        super('/BTCAPI/BuildEmail/GroupEmails');
        new UserEditableHtmlContent(document.getElementById("divEmailBody") as HTMLDivElement);
        this.InitFieldsFromLocalStorage()
        this.SetupListenersToWriteLocalStorage();
        this.SetupEventListeners();
        this.LoadGroupMembers();
    }

    protected async DoSubmit() {
        // Move markup from div to hidden textarea before posting form so it can be posted.
        const textArea: HTMLTextAreaElement = document.getElementById("hiddenEmailBody") as HTMLTextAreaElement;
        const divBodyHTML: HTMLDivElement = document.getElementById("divEmailBody") as HTMLDivElement;
        if (textArea !== null && divBodyHTML !== null)
            textArea.value = divBodyHTML.innerHTML;

        try { // localStorage may not exist or may not be enabled.
            localStorage.setItem('TrainingGroupRecipientsHeight', document.getElementById('GroupMembers')!.style.height!);
            localStorage.setItem('TrainingGroupRecipientEmailsHeight', document.getElementById('GroupMemberEmails')!.style.height!);
        }
        catch (e) {
            //alert(e);
        }

        await super.DoSubmit();
    }

    private InitFieldsFromLocalStorage() {
        try { // localStorage may not exist or may not be enabled.
            // Populate page fields from local storage
            const sSubjectLine: string | null = localStorage.getItem('TrainingGroupSubjectLine');
            if (sSubjectLine !== null)
                (document.getElementById('Subject') as HTMLInputElement).value = sSubjectLine;

            const sAdditionalAddress: string | null = localStorage.getItem('TrainingGroupAdditionalAddresses');
            if (sAdditionalAddress !== null)
                (document.getElementById('AdditionalAddresses') as HTMLInputElement).value = sAdditionalAddress;

            const divBodyHTML: HTMLDivElement = document.getElementById("divEmailBody") as HTMLDivElement;
            const sStoredBody: string | null = localStorage.getItem('TrainingGroupEmailBody');
            if (divBodyHTML !== null && sStoredBody !== null)
                divBodyHTML.innerHTML = sStoredBody;

            const sRecipientsHeight: string | null = localStorage.getItem('TrainingGroupRecipientsHeight');
            if (sRecipientsHeight !== null)
                document.getElementById('GroupMembers')!.style.height = sRecipientsHeight;

            const sRecipientEmailsHeight: string | null = localStorage.getItem('TrainingGroupRecipientEmailsHeight');
            if (sRecipientEmailsHeight !== null)
                document.getElementById('GroupMemberEmails')!.style.height = sRecipientEmailsHeight;
        }
        catch (e) {
            //alert(e);
        }
    }

    private SetupListenersToWriteLocalStorage() {
        try { // localStorage may not exist or may not be enabled.
            // Set up listeners to save changes to local storage
            const SubjectLine: HTMLInputElement = document.getElementById('Subject') as HTMLInputElement;
            SubjectLine.addEventListener('change', () => localStorage.setItem('TrainingGroupSubjectLine', SubjectLine.value));
            const AdditionalAddresses: HTMLInputElement = document.getElementById('AdditionalAddresses') as HTMLInputElement;
            AdditionalAddresses.addEventListener('change', () => localStorage.setItem('TrainingGroupAdditionalAddresses', AdditionalAddresses.value));
            const editableDiv: HTMLDivElement = document.getElementById('divEmailBody')! as HTMLDivElement;
            editableDiv.addEventListener('input', () => localStorage.setItem('TrainingGroupEmailBody', editableDiv.innerHTML));
        }
        catch (e) {
            //alert(e);
        }
    }

    private SetupEventListeners() {

        document.getElementById('CheckAll')!.addEventListener('click', () => {
            jQuery('.RecipientCB').prop('checked', true);
            SendGroupEmail.DrawEmailAddresses();
        });
        document.getElementById('UncheckAll')!.addEventListener('click', () => {
            jQuery('.RecipientCB').prop('checked', false);
            SendGroupEmail.DrawEmailAddresses();
        });

        jQuery.each(jQuery('input[name="SendEmailMode"]'), (_, rbSendType) => rbSendType.addEventListener('change', (evt) => {
            const rb: HTMLInputElement = evt.currentTarget as HTMLInputElement;
            if (rb.checked) {
                let SubmitButton = document.getElementById('Submit') as HTMLButtonElement;
                SubmitButton.value = (rb.id === 'TestMode' ? 'Send One Test Email' : 'Send to Everyone');
            }
        }));

        document.getElementById('ToGroupRoleId')!.addEventListener('change', () => this.LoadGroupMembers());
    }

    private LoadGroupMembers() {
        const RoleId: number = Number((document.getElementById('ToGroupRoleId') as HTMLSelectElement).value);

        this.pageBusyIndicator.Increment();
        jQuery('form').prop("disabled", true).fadeTo(0, 0.5); // Disable while processing Ajax
        const URL: string = '/BTCAPI/Roles/GetGroupMembers/' + RoleId;
        jQuery
            .get(URL,
                (JsonData: jsonGroupMembers) => {
                    let LIs: string = '';
                    for (const Member of JsonData) {
                        LIs += '<li>';
                        LIs += '    <input type="checkbox" class="RecipientCB" name="CheckedMembers[]" id="cbMemberId-' + Member.memberId + '" value="' + Member.memberId + '" ' + (Member.active ? 'checked' : '') + ' >';
                        LIs += '    <label for="cbMemberId-' + Member.memberId + '"' + (Member.active ? '' : 'class="InactiveGroupMember"') + ' >' + Member.memberName + '</label>';
                        LIs += '</li>';
                    }
                    document.getElementById('GroupMembers')!.innerHTML = LIs;
                    document.getElementsByName('CheckedMembers[]')!.forEach((cb: HTMLElement) => { cb.addEventListener('click', () => { SendGroupEmail.DrawEmailAddresses() }) });
                    SendGroupEmail.DrawEmailAddresses();
                },
                "json")
            .fail(async (jqXHR, textStatus, errorThrown) =>
                await HelperFunctions.DisplayAndProcessAjaxFailure(URL, "GET", jqXHR, textStatus, errorThrown, null, null)
            )
            .always(() => {
                this.pageBusyIndicator.Decrement();
                jQuery('form').prop("disabled", false).fadeTo(0, 1); // Re-enable after processing Ajax
            });
    }

    // Request from server a list of email addresses based on list of user IDs
    static DrawEmailAddresses() {
        // Make array of checked user IDs
        const checkedIds: string[] = [];
        document.getElementsByName('CheckedMembers[]')!.forEach(cb => {
            const cbMember = cb as HTMLInputElement;
            if (cbMember.checked)
                checkedIds.push(cbMember.value); // Push MemberId
        });
        const oData = { 'CheckedMemberIds': checkedIds };

        const URL: string = '/BTCAPI/Roles/GetGroupSelectedEmails';
        jQuery
            .post(URL,
                oData,
                (JsonData: [string]) => { // Get back plain array of strings
                    let sEmails: string = '';
                    JsonData.forEach((MemberEmail: string) => sEmails += (sEmails.length > 0 ? '; ' : '') + MemberEmail);
                    document.getElementById('GroupMemberEmails')!.innerHTML = sEmails;
                }
                , "json")
            .fail(async (jqXHR, textStatus, errorThrown) =>
                await HelperFunctions.DisplayAndProcessAjaxFailure(URL, "POST", jqXHR, textStatus,
                    errorThrown, null, oData)
            )
            ;
    }
}
