//import jQuery from "jquery";
import { ReportErrorToServer } from "../Lib/ReportErrorToServer";
import { SignInSignOutBase } from './SignInSignOutBase';

export class SignOut extends SignInSignOutBase {
    protected readonly SignOutPostURL = '/BTCAPI/SignInSignOut/SignOut';

    constructor(protected ServerLoggedIn: boolean) {
        super();

        if (!this.ServerLoggedIn)
            this.DoSignOut(); // If not signed-in on server, then do Firebase signout and go directly to sign-in

        document.getElementById("butSignOut")?.addEventListener("click", async () => await this.DoSignOut());
        this.pageBusyIndicator.Decrement();
    }

    private async DoSignOut() {
        this.pageBusyIndicator.Increment();

        await this.auth.signOut(); // Tell firebase that user is signing out
        if (this.ServerLoggedIn) {
            await jQuery
                .post(this.SignOutPostURL)
                .fail(async (jqXHR, textStatus, errorThrown) => {
                    await ReportErrorToServer.DisplayAndProcessAjaxFailure(this.SignOutPostURL, "POST", jqXHR, textStatus,
                        errorThrown, null, null);
                })
        }
        this.pageBusyIndicator.Decrement();
        window.location.href = '/SignIn/' // Redirect to sign-in page
    }
}
