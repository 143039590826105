import { ReportsBaseClass } from "../Lib/ReportsBaseClass";

export class Roles extends ReportsBaseClass {
    constructor() {
        super('tableRoles', '/BTCAPI/Roles/GetRoles');
        this.AjaxGetServerData();
    }

    ConvertRecordToTR(row: JQuery<HTMLTableRowElement>, record: {
        roleType: string, roleId: number,
            roleName: string, email: string, memberName: string, numParticipants: number
    }) {
        row.find('.spanRoleType').html(record.roleType);
        row.find('.spanRole').html('<a href="/admin/EditRole?RecordId=' + record.roleId + '">' + record.roleName + '</a>');
        row.find('.spanEmail').html(record.email);
        row.find('.spanMember').html(record.memberName);
        if (record.roleType === "Training Group")
            row.find('.spanNumParticipants').html('<a href="/admin/EditRoleMembers?RecordId='
                + record.roleId + '">' + record.numParticipants + '</a>');
        else
            row.find('.spanNumParticipants').html("&nbsp;");
    }
}
