import jQuery from "jquery";
import { EditRecordBase } from "../EntryPoints";
import { MyRecaptchaHandler } from "../Lib/ReCaptchaHandler";

export class DonateForm extends EditRecordBase {
    constructor(protected readonly TargetAPIUrl: string, protected readonly HttpVerb: string, protected AfterSubmitGotoWebURL: string = '',
        protected myRecaptchaHandler: MyRecaptchaHandler | null = null) {

        super(TargetAPIUrl, HttpVerb, AfterSubmitGotoWebURL, myRecaptchaHandler, true);
    }

    protected override EnableDisableControls() {
        super.EnableDisableControls();

        // Disable the "Other" amount field if a preset amount radio button is checked
        const DonationFixedAmt = jQuery("input[type='radio'][name='DonationPreSetAmt']:checked").val();
        const DonationAmountOther: HTMLInputElement = document.getElementById('DonationOtherAmount') as HTMLInputElement;
        DonationAmountOther.disabled = (DonationFixedAmt !== "Other");
    }

}
