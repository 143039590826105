import jQuery from "jquery";
import { ReportsBaseClass } from "../Lib/ReportsBaseClass";
import { HelperFunctions } from "../EntryPoints";

export class SundayRuns extends ReportsBaseClass {
    constructor() {
        super('tableSundayRuns', '/BTCAPI/SundayRuns/GetSundayRuns');

        // When user edits Run Start Time field, store new time to DB
        document.getElementById('RunStartTime')!.addEventListener('change', (evt) => {
            const PostToAPIUrl = '/BTCAPI/SundayRuns/SetRunStartTime';
            const tb: HTMLInputElement = evt.currentTarget as HTMLInputElement;
            const oData = { 'StartTime': tb.value };
            jQuery
                .post(PostToAPIUrl, oData)
                .done(() => jQuery('#ErrorMessage').html("")) // Clear prior err msg, if any
                .fail(async (jqXHR, textStatus, errorThrown) => {
                    await HelperFunctions.DisplayAndProcessAjaxFailure(PostToAPIUrl, "POST", jqXHR, textStatus,
                        errorThrown, null, oData)
                });
        });
        this.AjaxGetServerData();
    }

    ConvertRecordToTR(row: JQuery<HTMLTableRowElement>, record: any) {
        row.find('.spanTitle').html('<a href="/admin/EditSundayRun?RecordId=' + record.sundayRunId + '">' + record.runTitle + '</a>');
        row.find('.spanMiles').html(record.miles.toString());
        row.find('.spanLocationName').html(record.locationName);
        row.find('.spanCourseMap').html(record.courseMapURL);
    }

    QueryString() {
        let qFilters: string = '';

        const TitlesFilter: string = (document.getElementById('TitlesFilter') as HTMLInputElement).value;
        if (TitlesFilter !== '')
            qFilters += '&TitlesFilter=' + encodeURI(TitlesFilter);

        return qFilters;
    }
}
