
// There may be multiple reasons a page is busy. Use this class to track a
// count of them and display the busy indicator as long as something is still busy.
export class PageBusyIndicator {
    private count = 1; // Assume starting with busy signal shown on page.

    public Increment = async () => {
        this.count++;
        await this.ShowHideBusyIndicator();
    }

    public Decrement = async () => {
        if (this.count === 0)
            throw new Error("PageBusyIndicator count is zero and can't go negative.");
        this.count--;
        await this.ShowHideBusyIndicator();
    }

    public IsBusy = () => this.count > 0;

    private ShowHideBusyIndicator = async () => {
        // "idReportSpinner" and display style could be passed as ctor parameters to enhance re-use of this class
        document.getElementById("idReportSpinner")!.style.display = (this.count > 0 ? 'inline-block' : 'none');
    }
}
