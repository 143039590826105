import { MyRecaptchaHandler } from "./ReCaptchaHandler";
import { PageBusyIndicator } from "./PageBusyIndicator";
import { ReportErrorToServer } from "./ReportErrorToServer";

export class EditRecordBase {
    private pageBusyIndicator = new PageBusyIndicator();

    protected IsDirty: boolean = false;
    // Submit and delete buttons will not exist if user is not allowed to edit (ie view only).
    private readonly SubmitButton = document.getElementById('Submit') as HTMLButtonElement | null;
    private readonly DeleteButton = document.getElementById('Delete') as HTMLButtonElement | null;

    constructor(protected readonly TargetAPIUrl: string, protected readonly HttpVerb: string, protected AfterSubmitGotoWebURL: string = '',
        protected myRecaptchaHandler: MyRecaptchaHandler | null = null, protected FadeSuccessMsg: boolean = true) {
        this.SubmitButton?.addEventListener('click', async () => await this.DoSubmit());
        if (this.SubmitButton !== null)
            this.SubmitButton.disabled = true;

        if (this.myRecaptchaHandler !== null) {
            this.pageBusyIndicator.Increment(); // Recaptcha setup can take a while
            this.myRecaptchaHandler.promUserAccepted().then(() => this.EnableDisableControls());

            this.myRecaptchaHandler.promRecaptchaSetupDone().then(() => {
                this.pageBusyIndicator.Decrement();
                this.EnableDisableControls();
            });
        }

        this.DeleteButton?.addEventListener('click', () => this.DoDelete());

        // Call EnableDisable() when select, textarea, or input elements are changed.
        jQuery.each(jQuery('input, select, textarea, .ContentEditable'), (_, elem) =>
            elem.addEventListener('input', () => {
                this.IsDirty = true;
                this.EnableDisableControls();
            }));

        this.pageBusyIndicator.Decrement();
        this.EnableDisableControls();
    }

    // Overridable; Parent class may calc target URL
    protected BuildGotoURL = (_: JQuery.jqXHR<any> | undefined) => this.AfterSubmitGotoWebURL;

    protected EnableDisableControls() {
        let bRecaptchaTestRequiredAndFailed = this.myRecaptchaHandler !== null && !this.myRecaptchaHandler.GetUserPassedTest()
        let bDisableAllSubmitButtons = bRecaptchaTestRequiredAndFailed || this.pageBusyIndicator.IsBusy();
        if (this.SubmitButton !== null)
            this.SubmitButton.disabled = bDisableAllSubmitButtons || !this.IsDirty;

        if (this.DeleteButton !== null)
            this.DeleteButton.disabled = (this.HttpVerb === "POST"); // If POST, then adding new record; nothing to delete
    }

    protected async DoSubmit() {
        await this.AjaxPagePrep();

        // Note: Use FormData instead of jQuery("form").serializeArray() because FormData includes empty input fields (https://stackoverflow.com/a/48167577)
        const MyForm: FormData = new FormData(document.getElementById("EditRecord") as HTMLFormElement);
        jQuery
            .ajax({
                type: this.HttpVerb,
                data: MyForm,
                url: this.TargetAPIUrl,
                timeout: 10000,
                contentType: false,
                processData: false // Block jQuery from attempting to parse the FormData object on the data field
            })
            .done((data, textStatus, jqXHR) => {
                this.IsDirty = false;
                jQuery('#SuccessMessage').show();
                if (this.FadeSuccessMsg)
                    jQuery('#SuccessMessage').fadeOut(3000);
            })
            .fail(async (jqXHR, textStatus, errorThrown) =>
                await ReportErrorToServer.DisplayAndProcessAjaxFailure(this.TargetAPIUrl, this.HttpVerb, jqXHR, textStatus, errorThrown, MyForm, null))
            .always((data_jqXHR, textStatus, jqXHR_errorThrown ) => {
                this.pageBusyIndicator.Decrement();
                this.EnableDisableControls();
                jQuery('form').prop("disabled", false).fadeTo(0, 1); // Re-enable after processing Ajax
                const sPostSaveURL = this.BuildGotoURL(data_jqXHR);
                if (!this.IsDirty && sPostSaveURL !== "") // Record saved and got URL from jqXHR?
                    window.location.href = sPostSaveURL; // Eg: redirect to new record page or checkout page
            });
    }

    protected async DoDelete() {
        if (!window.confirm("Are you sure you want to permanently delete this record?"))
            return;
        await this.AjaxPagePrep();

        jQuery
            .ajax({
                type: "DELETE",
                url: this.TargetAPIUrl, // Id of record to be deleted is appended to url by PHP
                timeout: 10000
            })
            .done((data, _, __) => { window.location.href = this.BuildGotoURL(data); })
            .fail(async (jqXHR, textStatus, errorThrown) => {
                await ReportErrorToServer.DisplayAndProcessAjaxFailure(this.TargetAPIUrl, this.HttpVerb, jqXHR, textStatus, errorThrown, null, null)
            })
            .always(() => {
                this.pageBusyIndicator.Decrement();
                this.EnableDisableControls();
                jQuery('form').prop("disabled", false).fadeTo(0, 1); // Re-enable after processing Ajax
            });
    }

    private async AjaxPagePrep() {
        this.pageBusyIndicator.Increment();

        // Clear any prior messages
        jQuery('#SuccessMessage').hide();
        jQuery('#ErrorMessage').text('');
        jQuery('form').prop("disabled", true).fadeTo(0, 0.5); // Disable while processing Ajax
        this.EnableDisableControls(); // Disable submit button while form is being processed by Ajax.

        if (this.myRecaptchaHandler !== null)
            await this.myRecaptchaHandler.GetNewOneTimeV3TokenIfNeeded();
    }
}
