import { ReportsBaseClass } from "../Lib/ReportsBaseClass";

export class MiscPayments extends ReportsBaseClass {
    constructor() {
        super('tableMiscPayments', '/BTCAPI/ArbitraryPayments/GetMiscPayments');
        this.LoadYearsSelect();
        this.AjaxGetServerData();
    }

    ConvertRecordToTR(row: JQuery<HTMLTableRowElement>, record: {
        created: string, payer: string, email: string, category: string, billedAmount: string,
        paidAmount: string, message: string
    }) {
        row.find('.spanDate').html(record.created);
        row.find('.spanPayer').html(record.payer);
        row.find('.spanEmail').html("<a href='mailto:" + record.email + "'>" + record.email + "</a>");
        row.find('.spanCategory').html(record.category);
        row.find('.spanPaymentAmount').html(record.billedAmount);
        row.find('.spanPaidAmount').html(record.paidAmount);
        row.find('.spanComment').html(record.message);
    }

    QueryString() {
        let qFilters: string = '';

        qFilters += '&targetYear=' + parseInt((document.getElementById('Years') as HTMLSelectElement).value);
        qFilters += '&Category=' + (document.getElementById('PaymentCategory') as HTMLSelectElement).value;

        return qFilters;
    }

    LoadYearsSelect() {
        const YearsSelect: HTMLSelectElement = document.getElementById('Years') as HTMLSelectElement;

        const EarliestYear: number = 2018;
        const CurrentYear: number = (new Date()).getFullYear();
        for (let nYear: number = CurrentYear; nYear >= EarliestYear; nYear--) // Years in reverse order
            YearsSelect.add(new Option(nYear.toString(), nYear.toString()));

        YearsSelect.selectedIndex = 1; // Not index zero since that's "All Years"
    }
}
