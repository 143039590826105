import jQuery from "jquery";
import { UserEditableHtmlContent } from "../Lib/UserEditableHtmlContent";
import { ReportErrorToServer } from "../Lib/ReportErrorToServer";
import { SendEmailBase } from "../Lib/SendEmailBase";

// interface jsonEmailBody {
//     ResultCode: string;
//     Body: string;
// }

export class SendSundayRunEmail extends SendEmailBase {
    constructor(protected SundayRunId: number) {
        super('/BTCAPI/SundayRunEmail/SendEmail/' + SundayRunId);
        new UserEditableHtmlContent(document.getElementById("divOneTimeMessage") as HTMLDivElement);

        this.pageBusyIndicator.Increment();

        jQuery.each(jQuery('input[name="SendEmailMode"]'), (_, rb) => rb.addEventListener('change', () => {
            if ((document.getElementById('TestMode') as HTMLInputElement).checked)
                (document.getElementById('Submit') as HTMLButtonElement).value = 'Send One Test Email';
            else
                (document.getElementById('Submit') as HTMLButtonElement).value = 'Send to Everyone';
        }));

        jQuery('#Submit').prop("disabled", false).fadeTo(0, 1); // Re-enable submit button after processing Ajax
        document.getElementById('FromSenderRoleId')!.addEventListener('change', () => this.RewriteEmail());
        document.getElementById("IsPrivate")?.addEventListener("change", () => this.RewriteEmail());
        ['input', 'paste'].forEach((action) =>
            document.getElementById('divOneTimeMessage')?.addEventListener(action, () => this.RewriteEmail()));

        this.GetEmailBody();

        this.Validate();
    }

    private GetEmailBody() {
        // Get the static part of the email body based on the selected course
        const URL: string = '/BTCAPI/SundayRunEmail/GetEmailBody/' + this.SundayRunId;
        const MyForm = new FormData(document.getElementById("RunEmailSetup") as HTMLFormElement);
        jQuery
            .ajax({
                type: "POST",
                data: MyForm,
                url: URL,
                timeout: 10000,
                contentType: false,
                processData: false // Block jQuery from attempting to parse the FormData object on the data field
            })
            .done(
                (JsonData: string) => document.getElementById('EmailBody')!.innerHTML = JsonData
            )
            .fail(async (jqXHR, textStatus, errorThrown) =>
                await ReportErrorToServer.DisplayAndProcessAjaxFailure(URL, "POST", jqXHR, textStatus, errorThrown, MyForm, null))
            .always((_, __, jqXHRorString) => {
                this.RewriteEmail();
                this.pageBusyIndicator.Decrement();
            });
    }

    async DoSubmit() {
        const textArea: HTMLTextAreaElement = document.getElementById("hiddenOneTimeMessage") as HTMLTextAreaElement;
        const divBodyHTML: HTMLDivElement = document.getElementById("divOneTimeMessage") as HTMLDivElement;
        if (textArea !== null && divBodyHTML !== null)
            textArea.value = divBodyHTML.innerHTML;

        await super.DoSubmit();
    }

    private Validate() {
        const CoachId: number = Number((document.getElementById('FromSenderRoleId') as HTMLSelectElement)!.value);
        if (CoachId <= 0)
            document.getElementById('ErrorMessage')!.innerHTML = "Please select a coach";
        else
            document.getElementById('ErrorMessage')!.innerHTML = "";
    }

    private RewriteEmail() {
        const IsPrivate: boolean = (document.getElementById('IsPrivate') as HTMLInputElement).checked;
        let Msg = "<strong>Note:</strong> This week's BTC Sunday Long Run is private, for BTC members only.";
        if (!IsPrivate)
            Msg = "The BTC Sunday Long Run is open to everyone, members and non-members.";
        const elMsg: HTMLSpanElement = (document.getElementById('PublicPrivateMsg') as HTMLSpanElement);
        if (elMsg !== null) // Could be null if, for eg, an unknown SundayRunId was sent to server.
            elMsg.innerHTML = Msg;

        const SpecialNote: string = document.getElementById('divOneTimeMessage')!.innerHTML;
        if (SpecialNote.trim() === "" || SpecialNote === "<br>") // Sometimes Chrome can insert <br> on its own.
            jQuery("#EmailSpecialMessageParagraph").hide();
        else {
            jQuery("#EmailSpecialMessageParagraph").show();
            const spanEmailSpecialMessage: HTMLSpanElement = document.getElementById('EmailSpecialMessage') as HTMLSpanElement;
            if (spanEmailSpecialMessage !== null)
                spanEmailSpecialMessage.innerHTML = SpecialNote;
        }
    }
}
