import { ReportsBaseClass } from "../Lib/ReportsBaseClass";

export class Registrations extends ReportsBaseClass {
    constructor() {
        super('tableRegistrations', '/BTCAPI/Registrations/GetRegistrations');
        this.AjaxGetServerData();
    }

    ConvertRecordToTR(row: JQuery<HTMLTableRowElement>, record: {
        renewalId: string, memberName: string, email: string, phone: string, registrationDate: string,
        membershipType: string, joinType: string, billedAmount: string, paidAmount: string
    }) {
        row.find('.spanName').html('<a href="/admin/EditRegistration?RecordId=' + record.renewalId + '">'
            + record.memberName + '</a>');
        row.find('.spanEmail').html("<a href='mailto:" + record.email + "'>" + record.email + "</a>");
        row.find('.spanPhone').html("<a href='tel:" + record.phone + "'>" + record.phone + "</a>");
        row.find('.spanRegDate').html(record.registrationDate);
        row.find('.spanMembershipType').html(record.membershipType);
        row.find('.spanJoinType').html(record.joinType);
        row.find('.spanBilledAmount').html(record.billedAmount);
        row.find('.spanPaidAmount').html(record.paidAmount);
    }

    QueryString() {
        let qFilters: string = '';

        qFilters += "&MemberStatus=" + (document.querySelector('input[name=MemberStatus]:checked') as HTMLInputElement).value;

        const MembersFilter: string = (document.getElementById('MembersFilter') as HTMLInputElement).value;
        if (MembersFilter !== '')
            qFilters += '&MemberName=' + encodeURI(MembersFilter);

        return qFilters;
    }
}
