import jQuery from "jquery";
import { ReportsBaseClass } from "../Lib/ReportsBaseClass";
import { HelperFunctions } from "../EntryPoints";

export class BreweryRuns extends ReportsBaseClass {
    constructor() {
        super('tableBreweryRuns', '/BTCAPI/BreweryRuns/GetBreweryRuns');

        // When user edits Run Start Time field, store new time to DB
        document.getElementById('RunStartTime')!.addEventListener('change', (evt) => {
            const PostToAPIUrl = '/BTCAPI/BreweryRuns/SetRunStartTime';
            const tb: HTMLInputElement = evt.currentTarget as HTMLInputElement;
            const oData = { 'StartTime': tb.value };
            jQuery
                .post(PostToAPIUrl, oData)
                .done(() => jQuery('#ErrorMessage').html("")) // Clear prior err msg, if any
                .fail(async (jqXHR, textStatus, errorThrown) => {
                    await HelperFunctions.DisplayAndProcessAjaxFailure(PostToAPIUrl, "POST", jqXHR, textStatus,
                        errorThrown, null, oData)
                });
        });
        this.AjaxGetServerData();
    }

    ConvertRecordToTR(row: JQuery<HTMLTableRowElement>, record: any) {
        row.find('.spanTitle').html('<a href="/admin/EditBreweryRun?RecordId=' + record.breweryRunId + '">' + record.breweryName + '</a>');
        row.find('.spanAddress').html(record.breweryAddress);
    }

}
