
export class UserEditableHtmlContent {
    constructor(public readonly EditableElement: HTMLDivElement) {
        // execCommand deprecated with no alternative: https://stackoverflow.com/a/70831583
        document.execCommand("StyleWithCSS", false); // StyleWithCSS fixes using "bold" in Firefox (https://stackoverflow.com/q/11966111)

        document.querySelectorAll(".ContentEditableToolbar img").forEach((elem) => elem.addEventListener('click', (evt) => {
            const img: HTMLImageElement | null = evt.currentTarget as HTMLImageElement;
            const sCmd: string = img!.dataset.commandName as string;
            if (sCmd === 'hyperlink') {
                const sLnk = prompt('Write the URL here', 'https:\/\/');
                if (sLnk && sLnk !== '' && sLnk !== 'https://')
                    this.formatDoc('createlink', sLnk);
            }
            else
                this.formatDoc(sCmd, '');
        }));
    }

    private formatDoc(sCmd: string, sValue: string) {
        // execCommand deprecated with no alternative: https://stackoverflow.com/a/70831583
        document.execCommand(sCmd, false, sValue);
        this.EditableElement.focus();
    }
}
