import jQuery from "jquery";
import { EditRecordBase } from "../Lib/EditRecordBase";

export class EditRoleMembers extends EditRecordBase {
    constructor(protected readonly TargetAPIUrl: string) {
        super(TargetAPIUrl, "PUT");

        jQuery('input[type=checkbox][name=cbMember\\[\\]]').each((_, cbMember) => {
            cbMember.addEventListener('change', (evt) => {
                this.UpdateLIClassAssignment(evt.currentTarget as HTMLInputElement); // Update screen to show/hide check box in current list.
                this.DoSubmit();// Immediately save changes to DB.
            });
        });

        // Setup radio button filters.
        jQuery('input[type=radio][name=ShowMembers]').each((n: number, rb: HTMLElement) => rb.addEventListener('change', () => this.ShowHideAllLIs()));
        this.ShowHideAllLIs();
    }

    public ShowHideAllLIs() {
        jQuery('#ulRoleMembers > LI').each((n: number, li: HTMLElement) => this.ShowHideLI(li as HTMLLIElement));
    }

    public UpdateLIClassAssignment(cb: HTMLInputElement) {
        const LI: HTMLLIElement = cb.parentElement as HTMLLIElement;
        if (cb.checked && LI.className === 'NotInGroup')
            LI.className = 'InGroup';
        else if (!cb.checked && LI.className === 'InGroup')
            LI.className = 'NotInGroup';

        this.ShowHideLI(LI);
    }

    public ShowHideLI(LI: HTMLLIElement) {
        const FilterValue: string = (document.querySelector('input[type=radio][name=ShowMembers]:checked') as HTMLInputElement).value;

        let newDisplay: string = "none";
        if (FilterValue === 'AllMembers' || FilterValue === LI.className)
            newDisplay = "inline-block";

        LI.style.display = newDisplay;
    }
}
